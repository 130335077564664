import { render, staticRenderFns } from "./directors.vue?vue&type=template&id=3c5ad57b&scoped=true&"
import script from "./directors.vue?vue&type=script&lang=js&"
export * from "./directors.vue?vue&type=script&lang=js&"
import style0 from "./directors.vue?vue&type=style&index=0&id=3c5ad57b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5ad57b",
  null
  
)

export default component.exports