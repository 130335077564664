<template>
  <div class="recruiting-big">
    <div class="top-search dfr flex-center">
      <van-search
        v-model="keyword"
        :placeholder="placeholder"
        @blur="searchBlur"
        :left-icon="leftIcon"
      />
    </div>
    <!-- item -->
    <van-list
      v-model="loading"
      loading-text=""
      :offset="200"
      :finished="finished"
      v-if="siDongList.length"
      @load="onLoad"
    >
      <div class="dfc flex-center">
        <div
          class="recruiting-item"
          v-for="(item, index) in siDongList"
          :key="index"
          @click="goDetail(item)"
        >
          <div v-if="item.name" class="item-inner-box">
            <div class="item-box">
              <div class="dfr main-between">
                <div class="dfr">
                  <!-- 头像 -->
                  <div class="item-portrait-box">
                    <!-- 用户头像 -->
                    <img
                      v-if="item.avatar"
                      :src="item.avatar"
                      alt=""
                      class="w100 h100 object-cover"
                    />
                    <!-- 默认头像 -->
                    <img
                      v-else
                      src="../assets/images/default-portrait.png"
                      alt=""
                      class="w100 h100"
                    />
                    <!-- 徽章 -->
                    <div v-if="item.is_major_auth" class="item-badge-box">
                      <img
                        src="../assets/images/home-emblem.png"
                        alt=""
                        class="w100 h100"
                      />
                    </div>
                  </div>
                  <!-- 信息 -->
                  <div class="item-message">
                    <div class="dfc">
                      <div class="dfr cross-center">
                        <!-- 姓名 -->
                        <div class="fs32 fw600 fc333">{{ item.name }}</div>
                      </div>
                      <div class="item-com-job dfr cross-center flex-wrap">
                        <!-- 职位 -->
                        <div class="fs24 fw400 fc999">{{ item.job }}</div>

                        <div
                          v-if="item.job && item.company"
                          style="width: 0.02rem;height: 0.2rem;background: #CCCCCC;margin:0 0.12rem"
                        />

                        <div class="fs24 fw400 fc999">{{ item.company }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 描述 -->
            <div class="field-item-box dfr flex-wrap">
              <div v-for="(item_, index_) in item.field" :key="index_">
                <div class="field-item fs20 fw400 fc666">{{ item_.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else></empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placeholder: "请输入私董姓名",
      siDongList: [],
      keyword: "",
      leftIcon: require("../assets/images/search.png"),
      id: "",
      loading: false,
      page: 1,
      pageSize: 10,
      finished: false,
      fieldId: "",
      joinIds: [],
      userId: "",
      isSelect: false,
      isReplace: 0
    };
  },
  async created() {
    this.isReplace = parseInt(this.$route.query.isReplace) || 0;
    // this.fieldId = parseInt(this.$route.query.fieldId) || "";
    await this.getSiDongList();
  },
  activated() {
    let isSelect = this.$route.query.select;
    if (isSelect) {
      this.isSelect = true;
      this.placeholder = "请输入案主姓名";
      this.$title("选择会议的案主");
    } else {
      this.isSelect = false;
      this.placeholder = "请输入私董姓名";
      this.$title("私董列表");
    }

    let scrollTop = this.$localStore.get("directorScrollTop");
    window.scrollTo(0, scrollTop);
  },
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();
    this.$localStore.set("directorScrollTop", scrollTop);
    next();
  },

  methods: {
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const siDongList = await this.initSidongList();
        if (siDongList.length < this.pageSize) {
          this.siDongList = [...this.siDongList, ...siDongList];
          this.loading = false;
          this.finished = true;
        } else {
          this.siDongList = [...this.siDongList, ...siDongList];
          this.loading = false;
          this.finished = false;
        }
      }
    },

    async initSidongList() {
      let list = [];
      const data = {
        page: this.page,
        keyword: this.keyword,
        pageSize: this.pageSize
      };
      const resp = await this.$API
        .get("sidongList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.total = resp.data.length;
        list = resp.data.list;
        list.forEach(item => {
          item.canJoin = true;
          this.joinIds.forEach(id => {
            if (id == item.id) {
              item.canJoin = false;
            }
          });
        });
      }
      return list;
    },
    // 获取
    async getSiDongList() {
      this.siDongList = await this.initSidongList();
      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
    },
    goDetail(item) {
      if (this.isSelect) {
        const data = this.$localStore.get("meetingData");
        data.sidongInfo = item;
        data.sidongId = item.id;
        this.$localStore.set("meetingData", data);
        this.$goReplace("createMeeting", { isReplace: this.isReplace });
      } else {
        this.$go("detailS", { id: item.id });
      }
    },
    searchBlur() {
      this.page = 1;
      this.getSiDongList();
    }
  }
};
</script>

<style lang="less" scoped>
.item-delete {
  .disabled {
    filter: grayscale(100%);
  }
}
.recruiting-big {
  background: #f7f7f7;
  min-height: 100vh;
  padding-bottom: 0.2rem;
  .top-search {
    height: 0.96rem;
    width: 7.5rem;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 999;
    // border: 1px solid red;
  }
  .recruiting-item {
    .item-inner-box {
      width: 6.94rem;
      background: #ffffff;
      margin-top: 0.28rem;
      border-radius: 0.12rem;
      box-sizing: border-box;
      overflow: hidden;
    }
    .item-box {
      margin: 0.36rem 0 0.36rem 0.36rem;
      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .item-message {
        margin-left: 0.2rem;

        .item-com-job {
          margin-top: 0.12rem;
        }
      }
      .item-delete {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.36rem;
        img {
          object-fit: cover;
        }
      }
    }
    .field-item-box {
      margin: 0.24rem 0.36rem 0.36rem 0.36rem;
      .field-item {
        background: #f6f6f6;
        padding: 0.08rem 0.16rem;
        margin: 0.12rem 0.12rem 0 0;
      }
    }
  }
  .recruiting-item:last-child {
    margin-bottom: 0.4rem;
  }
  .recruiting-item:first-child {
    margin-top: 1.22rem;
  }
  .recruiting-button {
    padding: 0.36rem;
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    background: #f7f7f7;
  }
}
</style>
